import styled from "styled-components";

const AllCardsNumber = styled.div`
  font-size: 15px;
  padding: 0 5px 0 5px;
  color: #df6c92;
  font-weight: bold;
  text-align: center;
`;

export default AllCardsNumber;
