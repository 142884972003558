import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleText, {
  TitleContainer,
  UnderLogoText,
} from "../components/TitleText";
import { saveIsUserLogged } from "../store/userSlice";
import BtnBlock from "../components/BtnBlock";
import InnerFrame from "../components/InnerFrame";
import Rules from "./Rules";
import CardItemDeck from "./CardItemDeck";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeCategory } from "../store/categorySlice";
import { jwtDecode } from "jwt-decode";

const Welcome = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [selectedDesk, setSelectedDesk] = useState(t("categories.discover"));
  const dispatch = useDispatch();

  useEffect(() => {
    const defaultCategory = t("categories.discover");
    setSelectedDesk(defaultCategory);
    localStorage.setItem("category", selectedDesk);
  }, [t, i18n.language]);

  const handleStart = (newSelectedDesk) => {
    dispatch(changeCategory(newSelectedDesk));
    localStorage.setItem("category", newSelectedDesk);
    navigate("/game");
  };

  const handleChange = (event) => {
    if (event.target.textContent) {
      const newSelectedDesk = event.target.textContent;
      setSelectedDesk(newSelectedDesk);
      handleStart(newSelectedDesk);
    } else {
      localStorage.setItem("category", "Truth");
    }
  };
  const [rulesStateToUse, setRulesStateToUse] = useState(localStorage);
  const [showRules, setShowRules] = useState(
    rulesStateToUse.getItem("showRules") === "false" ? false : true
  );

  const handleRulesClose = () => {
    setShowRules(false);
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const hash = window.location.hash;

    const idTokenMatch = hash.match(/id_token=([^&]+)/);
    const idToken = idTokenMatch && idTokenMatch[1];

    if (idToken) {
      localStorage.setItem("idToken", idToken, 259200);

      const newUrl = currentUrl.replace(/callback.*/, "welcome");
      window.history.replaceState({}, document.title, newUrl);

      const decoded = jwtDecode(idToken);
      console.log("ID Token:", decoded);
    }
  }, []);

  useEffect(() => {
    const idToken = localStorage.getItem("idToken");
    const rulesState = idToken ? localStorage : sessionStorage;

    // dispatch(saveIsUserLogged(true));

    setRulesStateToUse(rulesState);
  }, []);

  return (
    <>
      {showRules ? (
        <Rules key={showRules} onRulesClose={handleRulesClose} />
      ) : (
        <InnerFrame>
          <BtnBlock>
            <TitleContainer>
              <span className="block"></span>
              <UnderLogoText> {t("welcome.wlcmTo")} SyncSoul!</UnderLogoText>
            </TitleContainer>
            <TitleText size="3vh" margintop="1vh">
              {t("welcome.choose")}:
            </TitleText>
            <CardItemDeck handleChange={handleChange} />
          </BtnBlock>
          <p
            style={{
              marginTop: "50px",
              color: "#df6c92",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              if (localStorage.getItem("idToken")) {
                const currentShowRules = JSON.parse(
                  localStorage.getItem("showRules")
                );
                localStorage.setItem("showRules", !currentShowRules);
                setShowRules(!currentShowRules);
              } else {
                sessionStorage.setItem("showRules", true);
                setShowRules(true);
              }
            }}
          >
            {t("welcome.showRulesBtn")}
          </p>
        </InnerFrame>
      )}
    </>
  );
};

export default Welcome;
