import styled from "styled-components";

const AllCardsText = styled.div`
  font-size: 16px;
  padding: 0 5px 0 5px;
  color: #1b1b3a;
  font-family: "Tenor Sans", serif;
  text-align: center;
  max-height: 160px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
`;

export default AllCardsText;
