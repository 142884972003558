import Sign from "./pages/Sign.jsx";
import Welcome from "./pages/Welcome.jsx";
import Cards from "./pages/Cards.jsx";
import User from "./pages/User.jsx";
import Header from "./pages/Header.jsx";
import CardItem from "./pages/CardItem.jsx";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./pages/Layout.jsx";
import { UserContextProvider } from "./context/user-context.js";
import "./App.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";

const App = () => {
  const myRouter = createBrowserRouter([
    {
      element: (
        <>
          <Header />
          <Layout />
        </>
      ),
      children: [
        {
          path: "/",
          element: <Sign />,
        },
        {
          path: "/logout",
          element: <Sign />,
        },
        {
          path: "/welcome",
          element: <Welcome />,
        },
        {
          path: "/game",
          element: <CardItem />,
        },
        {
          path: "/cards",
          element: <Cards />,
        },
        {
          path: "/*",
          element: <Welcome />,
        },
      ],
    },
  ]);

  return (
    <>
      <UserContextProvider>
        <I18nextProvider i18n={i18n}>
          <RouterProvider router={myRouter} />
        </I18nextProvider>
      </UserContextProvider>
    </>
  );
};

export default App;
