import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
  name: "category",
  initialState: { currentCategory: "Truth" },
  reducers: {
    changeCategory: (state, action) => {
      return { ...state, currentCategory: action.payload };
    },
  },
});

export const { changeCategory } = categorySlice.actions;
export default categorySlice.reducer;
