import React, { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import { useTranslation } from "react-i18next";

const Card = ({ content, zIndex, index, handleChange, isHovered }) => {
  const cardRef = useRef(null);

  const deckClickHandler = (event) => {
    console.log(event.target.textContent);
  };

  useEffect(() => {
    gsap.set(cardRef.current, { zIndex, index });

    gsap.to(cardRef.current, { rotation: index * 5, duration: 0.5 });
  }, [zIndex, index]);

  return (
    <div
      className="card"
      ref={cardRef}
      style={{
        position: "absolute",
        width: "30vw",
        maxWidth: "150px",
        height: "50vh",
        maxHeight: "200px",
        border: "2px solid ivory",
        borderRadius: "15px",
        boxShadow: `1px 0px 13px -2px ${
          isHovered ? "#df6c92" : "rgba(0, 0, 0, 0.75)"
        }`,
        background: "linear-gradient(0deg, #df6c92, #1b1b3a)",
        color: "#DEF6CA",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        transformOrigin: "center center",
        fontFamily: "Prata",
        cursor: "pointer",
      }}
      onClick={(e) => handleChange(e)}
    >
      {content}
    </div>
  );
};

const CardItemDeck = ({ handleChange }) => {
  const { t, i18n } = useTranslation();
  const [isTruthHovered, setIsTruthHovered] = useState(false);
  const [isDareHovered, setIsDareHovered] = useState(false);
  const TruthDeck = ["Truth", "“Truth”", "“Truth”", "“Truth”", "“Truth”"];
  const DareDeck = ["Dare", "Dare", "Dare", "Dare", "Dare"];

  return (
    <div style={{ display: "flex", maxHeight: "250px" }}>
      <div
        className="truth_card_container"
        style={{
          maxWidth: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "50px",
          marginRight: "30px",
        }}
        onMouseEnter={() => setIsTruthHovered(true)}
        onMouseLeave={() => setIsTruthHovered(false)}
      >
        {TruthDeck.map((card, index) => (
          <Card
            key={index}
            content={card}
            zIndex={TruthDeck.length - index}
            index={index}
            handleChange={handleChange}
            isHovered={isTruthHovered}
          />
        ))}
      </div>
      <div
        className="dare_card_container"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "50px",
          marginLeft: "30px",
          height: "300px",
        }}
        onMouseEnter={() => setIsDareHovered(true)}
        onMouseLeave={() => setIsDareHovered(false)}
      >
        {DareDeck.map((card, index) => (
          <Card
            key={index}
            content={card}
            zIndex={DareDeck.length - index}
            index={index}
            isHovered={isDareHovered}
            handleChange={handleChange}
          />
        ))}
      </div>
    </div>
  );
};

export default CardItemDeck;
