import styled from "styled-components";

const RulesBtn = styled.p`
  display: flex;
  height: 90%;
  font-family: "Oswald", sans-serif;
  font-size: 2.5vh;
  font-weight: bold;
  color: #df6c92;
  text-align: center;
  align-items: center;
  z-index: 10;
  &:hover {
    transition: 0.2s ease-in-out;
    transform: scale(1.01, 1.01);
    border-color: white;
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default RulesBtn;
