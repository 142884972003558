import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: { isUserLogged: false },
  reducers: {
    saveIsUserLogged: (state, action) => {
      return {
        ...state,
        isUserLogged: action.payload,
      };
    },
  },
});

export const { saveIsUserLogged } = userSlice.actions;
export default userSlice.reducer;
