import React from "react";
import { useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import animAvatar from "../assets/animAvatarUser.gif";
import noAnimAvatar from "../assets/noAnimAvatarUser.gif";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MyFormControlLabel from "../components/Radio";
import HeaderIcon from "../components/HeaderIcon";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { saveIsUserLogged } from "../store/userSlice";
import { changeCategory } from "../store/categorySlice";

const StyledCloseIcon = styled(CloseIcon)`
  color: #df6c92;
  border: 2px solid #df6c92;
  border-radius: 20px;
  &:hover {
    transition: 0.2s ease-in-out;
    transform: rotate(90deg);
    cursor: pointer;
  }
`;

const User = ({ closePopUp, popUpToggleHandler }) => {
  const theme = createTheme({
    palette: {
      pinky: {
        main: "#DEF6CA",
      },
    },
  });

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentCategory = localStorage.getItem("category");
  const [selectedDesk, setSelectedDesk] = useState(currentCategory);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const userDataTextStyle = {
    fontFamily: "Tenor Sans",
    fontSize: "1rem",
    color: "#DEF6CA ",
    borderBox: "2px solid black",
    "&:hover": {
      fontWeight: "bold",
    },
  };
  const listItemTextStyle = {
    fontFamily: "Tenor Sans",
    fontSize: "1rem",
    color: "#DEF6CA ",
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
    },
  };

  const close = () => {
    closePopUp();
  };
  const handleChange = (event) => {
    close();

    setSelectedDesk(event.target.value);
    dispatch(changeCategory(event.target.value));
    localStorage.setItem("category", event.target.value);
  };

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  const externalUrl = `https://${process.env.REACT_APP_AUTH_ENDPOINT}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=token&scope=email+openid+phone+profile+s3-presign%2Fcreate&logout_uri=${process.env.REACT_APP_LOGOUT_ENDPOINT}`;

  const handleLogOutClick = () => {
    popUpToggleHandler();
    localStorage.removeItem("isLoggedState");
    localStorage.removeItem("showRules");
    localStorage.removeItem("idToken");
    localStorage.removeItem("cashedQuestions");
    localStorage.removeItem("cardNumber");
    sessionStorage.clear();
    localStorage.setItem("currentLanguage", "EN");
    i18n.changeLanguage("en");
    window.dispatchEvent(new Event("storage"));
    dispatch(saveIsUserLogged(false));
    window.location.href = externalUrl;

    fetch("/logout", {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          // window.location.href = "localhost:3000";
          // navigate("/");
          // window.location.href = `${process.env.REACT_APP_API_ENDPOINT}`;
        }
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
    // navigate("..");
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const idToken = localStorage.getItem("idToken");

  const decodedToken = idToken ? jwtDecode(idToken) : "";

  return (
    <>
      <HeaderIcon>
        <img
          src={isHovered ? animAvatar : noAnimAvatar}
          alt="Avatar"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
          }}
        />
        <StyledCloseIcon onClick={close} />
      </HeaderIcon>
      <List
        sx={{
          width: "100%",
          bgcolor: "#51336e",
          fontSize: "5vh",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {decodedToken.name && (
          <ListItemButton>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" sx={userDataTextStyle}>
                  {decodedToken.name}
                </Typography>
              }
            />
          </ListItemButton>
        )}
        {decodedToken.email && (
          <ListItemButton>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" sx={userDataTextStyle}>
                  {decodedToken.email}
                </Typography>
              }
            />
          </ListItemButton>
        )}

        <ListItemButton onClick={handleClick}>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" sx={listItemTextStyle}>
                {t("user.categories")}
              </Typography>
            }
          />
          {open ? (
            <ExpandLess style={{ color: "#DEF6CA" }} />
          ) : (
            <ExpandMore style={{ color: "#DEF6CA" }} />
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <RadioGroup
            sx={{ margin: "0 0 0 10px" }}
            name="use-radio-group"
            defaultValue={currentCategory}
          >
            <MyFormControlLabel
              value={t("categories.discover")}
              label={t("categories.discover")}
              control={
                <Radio
                  color="default"
                  sx={{
                    "&.Mui-checked": {
                      color: theme.palette.pinky.main,
                    },
                    "&:not(.Mui-checked)": {
                      color: theme.palette.pinky.main,
                    },
                  }}
                />
              }
              checked={selectedDesk === t("categories.discover")}
              onChange={(event) => handleChange(event)}
              checkedcolor="#DEF6CA"
            />
            <MyFormControlLabel
              value={t("categories.dare")}
              label={t("categories.dare")}
              control={
                <Radio
                  color="default"
                  sx={{
                    "&.Mui-checked": {
                      color: theme.palette.pinky.main,
                    },
                    "&:not(.Mui-checked)": {
                      color: theme.palette.pinky.main,
                    },
                  }}
                />
              }
              checked={selectedDesk === t("categories.dare")}
              onChange={(event) => handleChange(event)}
              checkedcolor="#DEF6CA"
            />
          </RadioGroup>
        </Collapse>
        <ListItemButton>
          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Tenor Sans",
                  fontSize: "1rem",
                  color: "#df6c92 ",
                  "&:hover": {
                    fontWeight: "bold",
                    cursor: "pointer",
                  },
                }}
                onClick={handleLogOutClick}
              >
                {idToken === null ? t("user.goHome") : t("user.logOut")}
              </Typography>
            }
          />
        </ListItemButton>
      </List>
    </>
  );
};

export default User;
