import React, { useEffect, useState } from "react";
import WelcomeText from "../components/WelcomeText";
import AllCardsOne from "../components/AllCardsOne";
import AllCardsText from "../components/AllCardsText";
import CardText from "../components/CardText";
import CardFrame from "../components/CardFrame";
import AllCardsContainer from "../components/AllCardsContainer";
import AllCardsNumber from "../components/AllCardsNumber";
import AllCardsCategory from "../components/AllCardsCategory";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";

const Cards = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const idToken = localStorage.getItem("idToken");
  const currentLanguage = localStorage.getItem("currentLanguage");

  const [cardCntx, setCardCntx] = useState(["FetchTest", "Test2"]);
  const [isLoading, setIsLoading] = useState(false);
  const currentCategory = useSelector(
    (state) => state.category.currentCategory
  );
  const isDareCategory =
    currentCategory === "Dare" ||
    currentCategory === "Action" ||
    currentCategory === "Дія";

  useEffect(() => {
    setIsLoading(true);
    let params = {
      lang: currentLanguage.toLowerCase(),
      category: isDareCategory ? "dare" : "discover",
      quantity: idToken ? 1000 : 10,
    };
    const cashedQuestions = localStorage.getItem("cashedQuestions");

    const isTheSameCategory =
      currentCategory === localStorage.getItem("cashedCategory");
    const isTheSameLanguage =
      currentLanguage === localStorage.getItem("cashedLanguage");

    if (cashedQuestions && isTheSameLanguage && isTheSameCategory) {
      const cashedResult = JSON.parse(cashedQuestions);
      setCardCntx(cashedResult);
      setIsLoading(false);
    } else {
      const queryString = Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join("&");
      const url = `${process.env.REACT_APP_API_ENDPOINT}/questions?${queryString}`;

      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          setCardCntx(result);
          localStorage.setItem("cashedQuestions", JSON.stringify(result));
          localStorage.setItem("cashedLanguage", currentLanguage);
          localStorage.setItem("cashedCategory", currentCategory);
          setIsLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  }, [currentLanguage, currentCategory, isDareCategory]);

  const onCardClickHandler = (currentCardNumber) => {
    localStorage.setItem("cardNumber", currentCardNumber - 1);
    navigate(`../game`);
  };

  return (
    <>
      {!isLoading ? (
        <>
          <AllCardsCategory>
            {t("cards.cardsCategory")}:{" "}
            {t(`categories.${isDareCategory ? "dare" : "discover"}`)}
          </AllCardsCategory>
          <AllCardsContainer>
            {cardCntx.map((card) => {
              const currentCardNumber = card.id;
              return (
                <AllCardsOne
                  key={currentCardNumber}
                  onClick={() => onCardClickHandler(currentCardNumber)}
                >
                  <div data-id="keep-text-in-center"></div>
                  <AllCardsText>{card.question}</AllCardsText>
                  <AllCardsNumber>{currentCardNumber}</AllCardsNumber>
                </AllCardsOne>
              );
            })}
          </AllCardsContainer>
        </>
      ) : (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#def6ca"
          ariaLabel="three-dots-loading"
          wrapperStyle={{ justifyContent: "center" }}
          wrapperClass=""
          visible={true}
        />
      )}
    </>
  );
};

export default Cards;
