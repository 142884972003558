import * as React from "react";
import { styled } from "@mui/material/styles";
import { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ checked, checkedcolor }) => ({
  ".MuiFormControlLabel-label": {
    color: checked ? checkedcolor || "#a38a8a" : "#DEF6CA",
    borderBottom: "1px solid #DEF6CA",
    width: "90%",
    fontFamily: "Tenor Sans",
    fontWeight: checked ? "medium" : "light",
    fontSize: "1rem",
    transition: "font-weight 0.3s", // Add transition for a smoother effect
    "&:hover": {
      fontWeight: "bold",
    },
  },
}));

export default function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return (
    <StyledFormControlLabel
      checked={checked}
      checkedcolor={props.checkedcolor}
      {...props}
    />
  );
}
