import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BtnBlock from "../components/BtnBlock";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ScrollWrapper from "../components/ScrollWrapper";
import { StyledLink, ButtonDefault } from "../components/ButtonDefault";
import CardFrame from "../components/CardFrame";
import CardNumber from "../components/CardNumber";
import CardText from "../components/CardText";
import { useTranslation } from "react-i18next";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";

const CardItem = () => {
  const navigate = useNavigate();

  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const [isHovered, setIsHovered] = React.useState(false);

  const handleArrowHover = () => {
    setIsHovered(true);
  };

  const handleArrowLeave = () => {
    setIsHovered(false);
  };

  const idToken = localStorage.getItem("idToken");

  const currentLanguage = localStorage.getItem("currentLanguage");
  const currentCategory = useSelector((state) => {
    return state.category.currentCategory;
  });
  const isDareCategory =
    currentCategory === "Dare" ||
    currentCategory === "Action" ||
    currentCategory === "Дія";
  const { t } = useTranslation();
  const [cardCntx, setCardCntx] = useState([
    { id: 0, question: "The question is loading" },
  ]);
  const [cardNumber, setCardNumber] = useState(
    parseInt(localStorage.getItem("cardNumber"), 10) || 0
  );

  const [showLimitedCard, setShowLimitedCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [animationType, setAnimationType] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  const totalCardsNumber = isDareCategory ? 31 : 135;

  const onLeftPressed = () => {
    setAnimationType("left");
    setIsAnimating(true);

    setTimeout(() => {
      setCardNumber((prev) => prev - 1);
      setIsAnimating(false);
    }, 100);
  };
  const onRightPressed = () => {
    setAnimationType("right");
    setIsAnimating(true);

    setTimeout(() => {
      setCardNumber((prev) => prev + 1);
      setIsAnimating(false);
    }, 100);
  };

  useEffect(() => {
    setIsLoading(true);
    let params = {
      lang: currentLanguage.toLowerCase(),
      category: isDareCategory ? "dare" : "discover",
      quantity: idToken ? 1000 : 10,
    };
    const cashedQuestions = localStorage.getItem("cashedQuestions");

    const isTheSameCategory =
      currentCategory === localStorage.getItem("cashedCategory");
    const isTheSameLanguage =
      currentLanguage === localStorage.getItem("cashedLanguage");

    if (cashedQuestions && isTheSameLanguage && isTheSameCategory) {
      const cashedResult = JSON.parse(cashedQuestions);
      setCardCntx(cashedResult);
      setIsLoading(false);
    } else {
      const queryString = Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join("&");
      const url = `${process.env.REACT_APP_API_ENDPOINT}/questions?${queryString}`;

      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          setCardCntx(result);

          localStorage.setItem("cashedQuestions", JSON.stringify(result));
          localStorage.setItem("cashedLanguage", currentLanguage);
          localStorage.setItem("cashedCategory", currentCategory);
          setIsLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  }, [currentLanguage, currentCategory, isDareCategory]);

  const changeCategoryHandler = () => {
    localStorage.setItem("cardNumber", 0);
    setCardNumber(0);
    navigate("/welcome");
  };

  const ArrowForwardCards = () => {
    const color = idToken
      ? cardNumber < cardCntx.length - 1
        ? isHovered
          ? "white"
          : "#df6c92"
        : "#1b1b3a"
      : cardNumber < cardCntx.length
      ? isHovered
        ? "white"
        : "#df6c92"
      : "#1b1b3a";

    return color;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <ArrowBackIcon
          style={{
            color: isHover ? "#eba6bd" : "#df6c92",
          }}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={changeCategoryHandler}
        />
        <p
          style={{
            color: isHover ? "#eba6bd" : "#df6c92",
            fontWeight: "bold",
            margin: 0,
            paddingRight: "20px",
          }}
          onClick={() => changeCategoryHandler()}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {t("cards.cardsTitle")}
        </p>
      </div>
      <ScrollWrapper>
        <div
          data-id="arrow-back"
          style={{ height: "100%", alignItems: "center", display: "flex" }}
          onClick={() => {
            if (cardNumber >= 1) {
              onLeftPressed();
              localStorage.setItem("cardNumber", cardNumber - 1);
            }
          }}
        >
          <ArrowBackIosNewRoundedIcon
            className="arrow-prev"
            style={{
              color:
                cardNumber >= 1 ? (isHovered ? "white" : "#df6c92") : "#1b1b3a",
              cursor: "pointer",
            }}
            onMouseEnter={handleArrowHover}
            onMouseLeave={handleArrowLeave}
          />
        </div>
        {cardCntx[cardNumber] !== undefined && !showLimitedCard && (
          <CardFrame key={cardCntx[cardNumber].id}>
            <CardText animationType={animationType} isAnimating={isAnimating}>
              {isLoading ? (
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="black"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{ justifyContent: "center" }}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                cardCntx[cardNumber].question
              )}
            </CardText>
            <CardNumber>
              {cardCntx[cardNumber].id}/{totalCardsNumber}
            </CardNumber>
          </CardFrame>
        )}
        {showLimitedCard && (
          <CardFrame>
            <CardText>LIMITED ACCESS</CardText>
          </CardFrame>
        )}
        <div
          data-id="arrow-forward"
          style={{ height: "100%", alignItems: "center", display: "flex" }}
          onClick={() => {
            if (cardNumber < cardCntx.length - 1) {
              onRightPressed();
              localStorage.setItem("cardNumber", cardNumber + 1);
            } else {
              setShowLimitedCard(true);
            }
          }}
        >
          <ArrowForwardIosRoundedIcon
            className="arrow-next"
            style={{
              color: ArrowForwardCards(),
              cursor: "pointer",
            }}
            onMouseEnter={handleArrowHover}
            onMouseLeave={handleArrowLeave}
          />
        </div>
      </ScrollWrapper>
      <BtnBlock>
        <ButtonDefault>
          <StyledLink to="/cards">{t("cardItem.allCards")}</StyledLink>
        </ButtonDefault>
      </BtnBlock>
    </>
  );
};

export default CardItem;
