import React from "react";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import CardFrame from "../components/CardFrame";
import RulesFrame from "../components/RulesFrame";
import RulesText from "../components/RulesText";
import RulesBtn from "../components/RulesBtn";

const Rules = ({ onRulesClose }) => {
  const { t, i18n } = useTranslation();
  const rulesBtnHandler = () => {
    if (localStorage.getItem("idToken")) {
      const currentShowRules = JSON.parse(localStorage.getItem("showRules"));
      localStorage.setItem("showRules", !currentShowRules);
    } else {
      sessionStorage.setItem("showRules", false);
    }

    onRulesClose();
  };

  return (
    <RulesFrame>
      <CardFrame cardHeight={"65vh"} cardWidth={"40vh"} zIndex={10}>
        <RulesText fontSize={"1em"} fontWeight={"bold"} margin={"20px"}>
          {t("welcome.rulesTitle")}{" "}
        </RulesText>
        <RulesText fontSize={"0.55em"} margin={"0 15px 0 15px"}>
          {t("welcome.rules1")}{" "}
        </RulesText>
        <Trans>
          <RulesText fontSize={"0.55em"} margin={"15px"}>
            {" "}
            <strong>{t("welcome.rules2")} </strong>
            {t("welcome.rulesText2")}
          </RulesText>
        </Trans>
        <Trans>
          <RulesText fontSize={"0.55em"} margin={"0 15px 0 15px"}>
            <strong>{t("welcome.rules3")}</strong>
            {""}
            {t("welcome.rulesText3")}
          </RulesText>
        </Trans>
        <RulesText fontSize={"0.55em"} margin={"15px 15px 0 15px"}>
          {t("welcome.rules4")}{" "}
        </RulesText>
        <Trans>
          <RulesText fontSize={"0.55em"} margin={"5px 15px 0 15px"}>
            {t("welcome.rules5")} <strong>{t("welcome.rulesBtn")}</strong>{" "}
            {t("welcome.rules6")}
          </RulesText>
        </Trans>
      </CardFrame>
      <RulesBtn onClick={rulesBtnHandler}> {t("welcome.rulesBtn")} </RulesBtn>
    </RulesFrame>
  );
};
export default Rules;
