import styled from "styled-components";

const AllCardsNumber = styled.div`
  font-size: 25px;
  padding: 0 5px 0 5px;
  color: #df6c92;
  text-align: center;
  font-family: "Tenor Sans", serif;
`;

export default AllCardsNumber;
