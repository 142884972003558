import img from "../assets/cardBackground.jpg";
import styled from "styled-components";

const CardFrame = styled.div`
  overflow: hidden !important;
  border: 2px outset #df6c92;
  box-shadow: 1px 1px 13px -2px #df6c92;
  border-radius: 10px;
  height: ${(props) => props.cardHeight || "400px"};
  width: ${(props) => props.cardWidth || "250px"};
  font-family: "Oswald", sans-serif;
  font-size: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => props.justifyCnt || "center"};
  z-index: ${(props) => props.zIndex || "1"};
  background-image: url(${img});
  overflow: auto;
`;

export default CardFrame;
