import styled from "styled-components";
import img from "../assets/cardBackground.jpg";

const AllCardsOne = styled.div`
  border: 3px outset #df6c92;
  box-shadow: 1px 1px 14px -2px #df6c92;
  border-radius: 10px;
  height: 170px;
  width: 170px;
  background-image: url(${img});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    transition: 0.2s ease-in-out;
    transform: scale(1.05, 1.05);
    border: 3px outset #df6c92;
    cursor: pointer;
  }
`;

export default AllCardsOne;
