import React, { createContext, useContext, useState } from "react";

const UserContext = createContext({
  currentLanguage: "EN",
});

export default UserContext;

export function useUserContext() {
  return useContext(UserContext);
}

export function UserContextProvider({ children }) {
  const [currentLanguage, setCurrentLanguage] = useState("EN");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const changeLanguage = (newLanguage) => {
    setCurrentLanguage(newLanguage);
  };

  const toggleLoginStatus = () => {
    setIsLoggedIn((prevIsLoggedIn) => !prevIsLoggedIn);
  };

  return (
    <UserContext.Provider
      value={{ currentLanguage, changeLanguage, isLoggedIn, toggleLoginStatus }}
    >
      {children}
    </UserContext.Provider>
  );
}
