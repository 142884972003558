import styled, { keyframes, css } from "styled-components";

const drawerEntrance = keyframes`
 0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
`;
const drawerClosure = keyframes`
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 1;
  }
`;

const UserPopUp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 2vh;
  min-width: 222px;
  max-width: 100%;
  height: 100%;
  background-color: #51336e;
  z-index: 1000;
  ${({ isOpen }) =>
    isOpen
      ? css`
          animation: ${drawerEntrance} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `
      : css`
          animation: ${drawerClosure} 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);
        `}
`;
export default UserPopUp;
