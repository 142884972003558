import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  align-items: center;
  background: #1b1b3a;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: background 0.5s ease;
  width: 100%;
  color: #df6c92;
  text-decoration: none;
  &:hover {
    background: transparent;
    color: #1b1b3a;
  }
`;

const ButtonDefault = styled.button`
  max-width: 300px;
  background-image: linear-gradient(to right, #df6c92 0%, #eba6bd 100%);
  border-radius: 40px;
  box-sizing: border-box;
  display: block;
  color: #1b1b3a;
  font-family: "Tenor Sans", serif;
  font-size: 1rem;
  font-weight: bold;
  height: 50px;
  padding: 4px;
  min-height: 50px;
  width: 400px;
  position: relative;
  z-index: 2;
  border: none;
  margin-bottom: 7px;

  &:hover {
    background-color: #1b1b3a;
    color: #df6c92;
    border: 2px solid #df6c92;
  }
`;

export { StyledLink, ButtonDefault };
