import React, { useEffect } from "react";
import CardFrame from "../components/CardFrame";
import InnerFrame from "../components/InnerFrame";
import TitleText, {
  TitleContainer,
  UnderLogoText,
} from "../components/TitleText";
import { saveIsUserLogged } from "../store/userSlice";
import { StyledLink, ButtonDefault } from "../components/ButtonDefault";
import BtnBlock from "../components/BtnBlock";
import { useTranslation } from "react-i18next";
import HeartText from "../assets/HeartText.gif";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SyncSoulLogoOnly from "../assets/SyncSoulLogoOnly.gif";

const Sign = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserLogged = localStorage.getItem("isLoggedState") === "true";

  const enterApp = () => {
    sessionStorage.setItem("isLoggedState", "true");
    sessionStorage.setItem("showRules", "true");
    dispatch(saveIsUserLogged(true));
    navigate("/welcome");
  };

  const redirectToSignIn = () => {
    dispatch(saveIsUserLogged(true));
    localStorage.setItem("isLoggedState", "true", 259200);
    const externalUrl = `https://${process.env.REACT_APP_AUTH_ENDPOINT}/oauth2/authorize?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=token&scope=email+profile+openid+phone+s3-presign%2Fcreate&redirect_uri=${process.env.REACT_APP_CALLBACK_ENDPOINT}`;

    window.location.href = externalUrl;
  };

  useEffect(() => {
    if (isUserLogged) {
      navigate("/game");
    } else {
      return;
    }
  }, []);

  return (
    <InnerFrame>
      <BtnBlock>
        <img
          src={SyncSoulLogoOnly}
          alt="logo"
          style={{
            maxWidth: "150px",
            height: "auto",
          }}
        />
        <TitleText font={"Yeseva One"}>
          SyncSoul
          <br />
        </TitleText>
        <img
          src={HeartText}
          alt="logo"
          style={{
            maxWidth: "200px",
            height: "auto",
          }}
        />
      </BtnBlock>
      <BtnBlock>
        <ButtonDefault onClick={enterApp}>
          <StyledLink to="welcome">{t("sign.noAuthorization")}</StyledLink>
        </ButtonDefault>
        <ButtonDefault>
          <StyledLink onClick={redirectToSignIn}>
            {t("sign.google")} Google
          </StyledLink>
        </ButtonDefault>
      </BtnBlock>
    </InnerFrame>
  );
};

export default Sign;
