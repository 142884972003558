import React from "react";
import { useState, useEffect } from "react";
import animAvatar from "../assets/animAvatarHeader.gif";
import noAnimAvatar from "../assets/noAnimAvatarHeader.gif";
import HeaderIcon from "../components/HeaderIcon";
import UserPopUp from "../components/UserPopUp";
import User from "./User";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";

const StyledLngButton = styled(Button)`
  color: #df6c92;
  &:hover {
    cursor: pointer;
    background: white;
  }
`;

const Header = () => {
  const defaultLanguage = "EN";
  const currentLanguage = localStorage.getItem("currentLanguage");
  const { i18n } = useTranslation();
  const [popUpHandler, setPopUpHandler] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  // const [user, setUser] = useState(
  //   localStorage.getItem("isLoggedState") === "true"
  // );
  const isUserLogged = useSelector((state) => state.user.isUserLogged);

  console.log("isLoggedState", isUserLogged);
  const languages = {
    en: { nativeName: "EN" },
    fr: { nativeName: "FR" },
    ua: { nativeName: "UA" },
  };

  const popUpToggleHandler = () => {
    setPopUpHandler(!popUpHandler);
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = document.getElementById("permanent-anchor");

  const handleLngClick = (lng) => {
    localStorage.setItem("currentLanguage", languages[lng].nativeName);
    setOpen(false);
    i18n.changeLanguage(lng);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    // if (localStorage.getItem("idToken")) {
    //   setIsUserLogged(localStorage.getItem("isLoggedState"));
    // } else {
    //   setIsUserLogged(sessionStorage.getItem("isLoggedState"));
    // }
    //   localStorage.setItem("currentLanguage", "EN");
    //   function updateUserLog() {
    //     setUser(localStorage.getItem("isLoggedState") === "true");
    //   }
    //   window.addEventListener("storage", updateUserLog);
    //   return () => {
    //     window.removeEventListener("storage", updateUserLog);
    //   };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <HeaderIcon>
        {isUserLogged ||
        localStorage.getItem("isLoggedState") === "true" ||
        sessionStorage.getItem("isLoggedState") === "true" ? (
          <img
            src={isHovered ? animAvatar : noAnimAvatar}
            onClick={popUpToggleHandler}
            alt="Avatar"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
            }}
          />
        ) : (
          <div></div>
        )}
        <div>
          <StyledLngButton
            style={{
              maxWidth: "25px",
              maxHeight: "25px",
              minWidth: "25px",
              minHeight: "25px",
              color: "#df6c92",
            }}
            onClick={handleToggle}
            id="permanent-anchor"
          >
            {currentLanguage ?? defaultLanguage}
          </StyledLngButton>
          <StyledLngButton
            style={{
              maxWidth: "25px",
              maxHeight: "25px",
              minWidth: "25px",
              minHeight: "25px",
            }}
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label={currentLanguage}
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon
              sx={{
                color: "#df6c92",
              }}
            />
          </StyledLngButton>
        </div>
        <Popper
          sx={{
            zIndex: 1,
            color: "#df6c92",
          }}
          open={open}
          anchorEl={anchorRef}
          role={undefined}
          transition
          disablePortal
          placement="bottom-start"
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {Object.keys(languages).map((lng) => (
                      <MenuItem
                        key={lng}
                        onClick={() => handleLngClick(lng)}
                        type="submit"
                      >
                        {languages[lng].nativeName}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </HeaderIcon>
      {popUpHandler && (
        <>
          <UserPopUp isOpen={popUpHandler}>
            <User
              closePopUp={popUpToggleHandler}
              popUpToggleHandler={popUpToggleHandler}
            />
          </UserPopUp>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: "222px",
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.5)",
              zIndex: 10,
            }}
            onClick={popUpToggleHandler}
          />
        </>
      )}
    </>
  );
};

export default Header;
