import styled from "styled-components";

const AllCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 15px;
  padding: 15px;
`;

export default AllCardsContainer;
