import styled, { keyframes, css } from "styled-components";

const slideInRight = keyframes`
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;
const slideInLeft = keyframes`
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;
const slideOutLeft = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-1000px);
    opacity: 0;
  }
`;
const slideOutRight = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(1000px);
    opacity: 0;
  }
`;

const CardText = styled.p`
  display: flex;
  height: 90%;
  font-family: "Oswald", sans-serif;
  font-size: ${(props) => props.fontSize || "0.8rem"};
  font-weight: bold;
  color: ${(props) => props.color || "black"};
  padding: ${(props) => props.padding || "40px 10px 0 15px"};
  text-align: center;
  align-items: center;

  ${({ animationType, isAnimating }) =>
    animationType === "right" &&
    css`
      animation: ${isAnimating ? slideOutLeft : slideInRight} 0.2s ease-out;
    `}
  ${({ animationType, isAnimating }) =>
    animationType === "left" &&
    css`
      animation: ${isAnimating ? slideOutRight : slideInLeft} 0.2s ease-out;
    `}
`;

export default CardText;
