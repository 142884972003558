import styled from "styled-components";

const ScrollWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 2vh 0 0 0;
  flex-direction: ${(props) => props.flexDirection || "row"};
  height: 75%;
`;

export default ScrollWrapper;
