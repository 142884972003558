import styled, { keyframes } from "styled-components";

const focusInContract = keyframes`
  0% {
    letter-spacing: 1em;
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
`;
const mainBlock = keyframes`
0% {
    width: 0%;
    left: 0;

  }
  50% {
    width: 100%;
    left: 0;

  }
  100% {
    width: 0;
    left: 100%;
  }
`;
const mainFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const TitleText = styled.p`
  width: 100%;
  font-family: ${(props) => props.font || "Prata"};
  font-size: ${(props) => props.size || "4.2vh"};
  color: ${(props) => props.color || "#DF6C92"};
  display: flex;
  justify-content: center;
  margin: 0 0 10px 0;
  white-space: pre-line;
`;
export const UnderLogoText = styled.p`
  width: 100%;
  font-family: Prata;
  font-size: 3vh;
  color: #df6c92;
  display: flex;
  justify-content: center;

  -webkit-animation: ${mainFadeIn} 0.5s forwards;
  -o-animation: ${mainFadeIn} 0.5s forwards;
  animation: ${mainFadeIn} 0.5s forwards;
  animation-delay: 0s;
  opacity: 0;
  align-items: baseline;
  position: relative;
`;

export const TitleContainer = styled.div`
  width: 300px;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;

  .block {
    width: 0%;
    height: inherit;
    background: #df6c92;
    position: absolute;
    animation: ${mainBlock} 0.75s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
    display: flex;
  }
`;

export default TitleText;
