import styled from "styled-components";

const WelcomeText = styled.p`
  width: 100%;
  font-family: "Oswald", sans-serif;
  font-size: ${(props) => props.size || "4vh"};
  color: ${(props) => props.color || "#df6c92"};
  text-align: center;
  margin: auto;
  margin-top: ${(props) => props.margintop || "8vh"};
  margin-bottom: ${(props) => props.marginbtm || "1vh"};
  white-space: pre-line;
`;

export default WelcomeText;
