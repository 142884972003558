import styled from "styled-components";

const RulesText = styled.p`
  font-family: "Oswald", sans-serif;
  font-size: ${(props) => props.fontSize || "2vh"};
  font-weight: ${(props) => props.fontWeight || "light"};
  color: ${(props) => props.color || "black"};
  padding: 0;
  margin: ${(props) => props.margin || "0"};
  text-align: center;
  align-items: center;
`;

export default RulesText;
