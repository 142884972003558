import styled from "styled-components";

const CardNumber = styled.p`
  display: flex;
  align-items: center;
  height: 10%;
  font-family: "Oswald", sans-serif;
  font-size: ${(props) => props.size || "0.7rem"};
  font-weight: bold;
  color: ${(props) => props.color || "black"};
  margin: auto;
`;

export default CardNumber;
